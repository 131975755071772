<template>

  <div class="p-d-flex p-flex-column container">

    <div id="d1" style="display:none;z-index: 1000;position: relative;">
      <ModalAlwaysShow
        tipo='error'
        :messageErr="internalizationDetailCons.detailConsErrTimeOutLabel"
        :detailErr="internalizationDetailCons.detailConsErrTimeOutMsg"
      />
    </div>

    <TextBox :text="data.infoMessage"/>

    <ConsentStatusCardDetail
      :cardStatus="data.cardStatus"
      :cardStatusTitle="data.cardStatusTitle"
      :cardStatusDescription="data.cardStatusDescription"
      :cardInfoTitle="internalizationDetailCons.detailConsDownBoxTitle"
      :cardInfoDescription="data.value"
      :initiatorTitle="internalizationDetailCons.detailConsDownBoxInitiatorTitle"
      :initiatorName="data.initiatorName"
      :payerDetailTitle="internalizationDetailCons.detailConsDownBoxPayerLabel"
      :payerCpf="data.payerCpf"
      :payerName="data.payerName"
      :payerBranch="data.payerBranch"
      :payerCc="data.payerCc"
      :localInstrument="data.localInstrument"
      :beneficiaryDetailTitle="internalizationDetailCons.detailConsDownBoxBeneficiaryLabel"
      :beneficiaryCnpj="data.beneficiaryCnpj"
      :beneficiaryName="data.beneficiaryname"
      :beneficiaryPixLabel="internalizationDetailCons.detailConsDownBoxPixLabel"
      :beneficiaryPixValue="data.beneficiaryPixValue"
      :beneficiaryBankLabel="internalizationDetailCons.detailConsDownBoxBeneficiaryBankLabel"
      :beneficiaryBankCode="data.beneficiaryBankCode"
      :beneficiaryAggLabel="internalizationDetailCons.detailConsDownBoxBeneficiaryAggLabel"
      :beneficiaryAgg="data.beneficiaryAgg"
      :beneficiaryAccount="data.beneficiaryAccount"
      :beneficiaryQRDinLabel="internalizationDetailCons.detailConsDownBoxQRDinLabel"
      :beneficiaryQREstLabel="internalizationDetailCons.detailConsDownBoxQREstLabel"
      :consentCreatorLabel="data.consentCreatorLabel"
      :consentCreatorName="data.consentCreatorName"
      :initiationDateTitle="internalizationDetailCons.detailConsDownBoxStartDateLabel"
      :scheduledDateTitle="internalizationDetailCons.detailConsDownBoxScheduleDateLabel"
      :initiationDate="data.paymentIniciationData"
      :scheduledDate="data.paymentScheduledData"
      :valueTitle="internalizationDetailCons.detailConsDownBoxPayValueLabel"
      :value="data.value"
      :paymentFormTitle="internalizationDetailCons.detailConsDownBoxPayFormLabel"
      :paymentForm="data.formOfPayment"
      :consentIDTitle="internalizationDetailCons.detailConsDownBoxIdConsLabel"
      :consentID="data.consentID"
      :haveDebtorAccount="data.haveDebtorAccount"
      :showConsentStatus="data.showConsentStatus"
      :payFeeTitle="internalizationDetailCons.detailConsDownBoxPayFeeLabel"
      :payFee="data.payFee"
    />

    <ExceptionModal
      ref="exceptionWarnig"
      tipo='error'
      :messageErr="internalizationDetailCons.detailConsErrFetchDataLabel"
      :detailErr="internalizationDetailCons.detailConsErrFetchDataMsg"
    />
    <ExceptionModal
      ref="exceptionWarnig2"
      tipo='error'
      :messageErr="internalizationDetailCons.detailConsErrCommunicationFailLabel"
      :detailErr="internalizationDetailCons.detailConsErrCommunicationFailMsg"
    />
    <ExceptionModal
      ref="timeoutwarning"
      tipo='error'
      :messageErr="internalizationDetailCons.detailConsErrFetchLabel"
      :detailErr="internalizationDetailCons.detailConsErrFetchMsg"
    />
    
  </div>

</template>

<script>
Date.prototype.addHours = function(h){
    this.setHours(this.getHours()+h);
    return this;
}

import 
{ 
  SessionObjects,
  endSess,
  AccountTypes,
  ConsentStatus, 
  GetConsentStatus, 
  GetAccountType,
  GetConsentCssClass,
  getInternalization,
  MaskCpfCnpj,
  getWithExpiry 
} from '@/util/util.js';

async function fetchConsultData(lastConsent) {

  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };

  let response = await fetch(`${process.env.VUE_APP_CONSENTS}/consent/consent-detail/${lastConsent}`, requestOptions);
  if (!response.ok) {
    return "fetchErr";
  }
  let consentR = await response.json();
  return consentR;
}

export default {
    data(){
      return {
        iconMeusCompartilhamentos: 'meus-compartilhamentos',
        iconMeusConsentimentos: 'meus-consentimentos',
        aboutRoute: 'MyConsents',
        data : {},
        onLine:true,
      }
  },
  methods: {
    test(message) {
      console.log(message);
    },
    checkOnline(){
      if(!navigator.onLine){
        endSess('dados')
        this.$refs.exceptionWarnig2.enable();
        this.$root.$refs.loadingModal.disable();
      }
    },
    defTimeOut(){
      var timeout;
      function refresh(){
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          var el = document.getElementById("d1");
          el.style.display = "flex"; 
        },  300000)
      }
      refresh()
      document.addEventListener('click', refresh);
    },
    showWarningModal(){
      this.$refs.exceptionWarnig.enable();
    },
    parseStatus(status) {
      return GetConsentCssClass(status);
    },
    parseTitle(status) {
      return GetConsentStatus(status);
    },

    convertUtcDateToString(date, locale, isDateTime){
      const tmp = new Date(date)
      const newDate = new Date(date).addHours(3)
      return isDateTime 
        ? tmp.toLocaleString(locale)
        : newDate.toLocaleDateString(locale)
    },

    parseConsentDate(status, consentBody, locale) {
      switch(status) {
        case ConsentStatus.ACCEPTED : return `${this.convertUtcDateToString(consentBody.statusUpdateDateTime, locale, true).replace(' ',' - ')}`
        case ConsentStatus.CONSUMED : return `${this.convertUtcDateToString(consentBody.statusUpdateDateTime, locale, true).replace(' ',' - ')}`
        case ConsentStatus.REJECTED:  return `${this.convertUtcDateToString(consentBody.statusUpdateDateTime, locale, true).replace(' ',' - ')}`;
        case ConsentStatus.WAITING:   return `${this.internalizationDetailCons.myConsAndDetailConsUpBoxInnerLabelWaiting} ${this.convertUtcDateToString(consentBody.expirationDateTime, locale, true).replace(' ',' - ')}`;
        default:return this.convertUtcDateToString(consentBody.statusUpdateDateTime, locale, true);
      }
    },
    parseCurrency(locale, type, value) {
      var formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: type,
        minimumFractionDigits: 2,
        maximumFractionDigits: 4
      });

      return formatter.format(value); 
    },
    parseBranch(branchCode) {
      return `${this.internalizationDetailCons.detailConsDownBoxPayerAggLabel} ${branchCode}`;
    },
    parseAccount(accountType, accountNumber) {
      return `${GetAccountType(accountType)}: ${accountNumber}`;
    },
    maskCpfCnpj(cpfCnpj, size) {
      return size == 11 
        ? `CPF ${MaskCpfCnpj(cpfCnpj, size)}`
        : `CNPJ ${MaskCpfCnpj(cpfCnpj, size)}`;
    },
    getInfoMessage(status) {
      switch(status) {
        case ConsentStatus.ACCEPTED: return this.internalizationDetailCons.detailConsMsgStatusAccepted;
        case ConsentStatus.REJECTED: return this.internalizationDetailCons.detailConsMsgStatusRejected;
        case ConsentStatus.WAITING: return this.internalizationDetailCons.detailConsMsgStatusWaiting;
        default: return "";
      }
    },
    isEmpty(obj){
      if (obj === 'undefined' || obj == null)
        return true;
      
      if (typeof obj === 'string')
        return obj.replace(/\s/g, '').length < 1;

      return false;
    }
  },
  async created(){

    this.internalizationDetailCons = getInternalization();

    this.defTimeOut();    
    this.$root.$refs.loadingModal.enable();
    const holder = JSON.parse(getWithExpiry('dados'))
    if(!holder){
      this.$root.$refs.exceptionWarnigFetchingData.enable();
    }
    else{

      try{

        setInterval(() => {
          this.checkOnline();
        }, 3000);

        var lastConsent = sessionStorage.getItem(SessionObjects.LAST_SELECTED_CONSENT);
        let ctrl = new AbortController();
        let signal = ctrl.signal;
        let consentCreatorLabels = {
          "waiting" : "",
          "authorised": this.internalizationDetailCons.detailConsDownBoxCreatorAcceptedLabel,
          "consumed": this.internalizationDetailCons.detailConsDownBoxCreatorAcceptedLabel,
          "rejected": this.internalizationDetailCons.detailConsDownBoxCreatorRejectedLabel
        };

        // set timeout
        let timeOut = new Promise(resolve => {
          setTimeout(async () => {
              ctrl.abort() // abort fetch request
              resolve("timeout")
          }, 30000);
        });

        let result = await Promise.race([fetchConsultData(lastConsent), timeOut]);

        if(result == 'timeout') {
          this.$root.$refs.loadingModal.disable();
          this.$refs.timeoutwarning.enable();
          this.data.showConsentStatus = false;
          return;
        }
        else if(result == 'fetchErr'){
          this.showWarningModal();
          sessionStorage.removeItem(SessionObjects.LAST_SELECTED_CONSENT); //remove o id do ultimo consentimento da sessao
          this.$root.$refs.loadingModal.disable();
        }
        else{
          //let payload = JSON.parse(getWithExpiry('dados'));
          let payload = result.JWE;
          const consentDetailCmpl = result.consentDetailCmpl ? result.consentDetailCmpl :  ""
          let consentBody = result.data.consentBody;
          let ssaDetail = result.ssaDetail;
          let parsedStatus = consentBody.data.status;
          let payment = consentBody.data.payment;
          let creditor = consentBody.data.creditor;
          let accountUsed = { };

          let tmp = {
            consentID: "",
            detailView: "DetailConsent",
            cardStatus: "",
            infoMessage: "",
            cardStatusTitle: "",
            cardStatusDescription: "",
            initiatorName: "",
            payerCpf: "",
            payerName: "",
            payerBranch: "",
            payerCc: "",
            localInstrument: "",
            beneficiaryCnpj: "",
            beneficiaryname:"",
            beneficiaryBankCode: "",
            beneficiaryAgg: "",
            beneficiaryAccount: "",
            paymentIniciationData: "",
            paymentScheduledData: "",
            value:"",
            formOfPayment:"",
            haveDebtorAccount: true,
            showConsentStatus: true,
            payFee:""
          };

          console.log(consentBody.data.payment.date)

          tmp.consentID = consentBody.data.consentId;
          tmp.initiatorName = (!this.isEmpty(ssaDetail) ? ssaDetail.org_name : null);
          tmp.infoMessage = this.getInfoMessage(parsedStatus);
          tmp.cardStatus = this.parseStatus(parsedStatus);
          tmp.cardStatusTitle = this.parseTitle(parsedStatus);
          tmp.cardStatusDescription = this.parseConsentDate(parsedStatus, consentBody.data, 'pt-BR');
          tmp.value = this.parseCurrency('pt-BR', payment.currency, payment.amount);
          tmp.formOfPayment = payment.type;
          tmp.beneficiaryname = creditor.name;
          tmp.paymentIniciationData = this.convertUtcDateToString(consentBody.data.payment.date, 'pt-BR', false).replace(' ',' - ');
          tmp.paymentScheduledData = consentBody.data.payment.schedule ? this.convertUtcDateToString(consentBody.data.payment.schedule.single.date, 'pt-BR', false).replace(' ',' - ') : null;
          tmp.beneficiaryCnpj = this.maskCpfCnpj(creditor.cpfCnpj, creditor.cpfCnpj.length);
          tmp.beneficiaryPixValue = payment.details.proxy;
          tmp.creationDateTime = consentBody.data.creationDateTime;

          // cpf cnpj
          if (consentBody.data.businessEntity != null){
            tmp.payerCpf = this.maskCpfCnpj(
              consentBody.data.businessEntity.document.identification, 
              consentBody.data.businessEntity.document.identification.length
            );
          }
          else {
            tmp.payerCpf = this.maskCpfCnpj(
              consentBody.data.loggedUser.document.identification,
              consentBody.data.loggedUser.document.identification.length
            );
          }

          // VALIDAR SE O CPF/CNPJ CONTINUA NO MESMO LUGAR
          if (consentDetailCmpl) {
            const payerData = consentDetailCmpl.accountDetail
            // tmp.payerCpf = this.maskCpfCnpj(payerData.number, payerData.number.length);
            tmp.payerName = payerData.owner
            tmp.payerBranch = this.parseBranch(payerData.issuer)
            tmp.payerCc = this.parseAccount(payerData.accountType, payerData.number);

            if (consentDetailCmpl.bankFee) {
              tmp.payFee =  this.parseCurrency('pt-BR', payment.currency, consentDetailCmpl.bankFee);
            }
            
          }

          //sentença de codigo da gaia que pega as informações do JWE (dados do pagador)
          /*
          if (!this.isEmpty(payload) && !this.isEmpty(payload.bank_fee) && !isNaN(payload.bank_fee))
            tmp.payFee = this.parseCurrency('pt-BR', payment.currency, payload.bank_fee);

          if (!this.isEmpty(payload) && (!this.isEmpty(result.data.accountIds) || !this.isEmpty(payload.account_id))){
            // authorized and rejected
            if (!this.isEmpty(result.data.accountIds)){
              payload.accounts.forEach(element => {
                if(element.account_id == result.data.accountIds[0])
                  accountUsed = element;
              });
            }
            // awaiting
            else{
              payload.accounts.forEach(element => {
              if(element.account_id == payload.account_id)
                accountUsed = element;
              });
            }

            // debitor Cc and Branch
            tmp.payerCc = this.parseAccount(accountUsed.account_type, accountUsed.account_number);
            tmp.payerBranch = this.parseBranch(accountUsed.account_agency);

            // debitor owner name
            if (!this.isEmpty(accountUsed.owner)){
              tmp.payerName = accountUsed.owner;
            }

          }
          */

          // beneficiary payment data
          tmp.localInstrument = payment.details.localInstrument.toUpperCase();

          if (tmp.localInstrument != 'MANU' &&
            tmp.localInstrument != 'DICT' &&
            tmp.localInstrument != 'INIC' &&
            tmp.localInstrument != 'QRDN' &&
            tmp.localInstrument != 'QRES'){
              throw 'invalid localInstrument';
          }

          if (tmp.localInstrument == 'MANU'){
            tmp.beneficiaryBankCode    = payment.details.creditorAccount.ispb;
            tmp.beneficiaryAgg         = payment.details.creditorAccount.issuer;
            tmp.beneficiaryAccount     = this.parseAccount(payment.details.creditorAccount.accountType, payment.details.creditorAccount.number);
          }
          
          // rejector and aproover
          if (tmp.cardStatus != "waiting"){
            tmp.consentCreatorLabel = (tmp.cardStatus == "rejected" ? consentCreatorLabels.rejected : consentCreatorLabels.consumed);
            tmp.consentCreatorName = consentDetailCmpl.consentPsuName;
          }
          else{
            tmp.consentCreatorLabel = "";
            tmp.consentCreatorName = "";
          }

          this.data = JSON.parse(JSON.stringify(tmp));
          
          sessionStorage.removeItem(SessionObjects.LAST_SELECTED_CONSENT);    //remove o id do ultimo consentimento da sessao 
          sessionStorage.setItem(SessionObjects.BACK_TO_CONSENT_LIST, true);  //se uma ação de voltar for executada, nao mostra o dialogo de confirmacao
          this.$root.$refs.loadingModal.disable();
        }
      }
      catch(err){
        console.log('erro ' + err);
        this.showWarningModal();
        this.$root.$refs.loadingModal.disable();
      }
    }
  },
  mounted(){
    this.$root.$refs.mainNavbar.showGoBackButton();
  }
}
</script>

<style scoped>

.container {
  background-color: var(--page-background-color);
  padding: 1em;
}
.card {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align:justify;
}

</style>          